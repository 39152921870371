<template>
    <div>
      <HeaderComponent />
      <section class="content">
        <!-- Левый сайдбар с выбором -->
        <div class="sidebar">
          <div class="category">
            <div class="title">
              <h2>Шаблоны</h2>
            </div>
            <ul>
              <!-- Пока что в списке один элемент -->
              <li @click="navigateToTemplate">Шаблон Вайлдберриз</li>
            </ul>
          </div>
        </div>
        <!-- Правая часть – два основных контейнера -->
        <div class="articles">
          <!-- Первый контейнер -->
          <div class="template-section">
            <h1>Защита прав потребителя по Вайлдберриз</h1>
            <p>
              В нашей практике довольно часто встречаем случаи, когда клиенты крупных маркетплейсов и агрегаторов не отвечают по своим обязательствам и в нарушение Закона О Защите прав потребителей не выполняют требования Закона.
            </p>
            <p>
              На такие случаи, мы, как профессиональные юристы, советники по правовым вопросам, рекомендуем незамедлительно обращаться с претензией в маркетплейс и жалобой в Роспотребнадзор. Это достаточно действенные способы решения проблемы, лучше, чем ничего не делать и ждать милости от менеджера.
            </p>
            <p>
              Закон всегда работает - если его требуют исполнять !
            </p>
          </div>
          <!-- Второй контейнер -->
          <div class="template-section">
            <h1>Образец претензии в Вайлдберриз</h1>
            <p><strong>В:</strong> ООО «Вайлдберриз»</p>
            <p>
              <strong>адрес:</strong>
              142181, Московская область, г. Подольск, деревня Коледино, Территория Индустриальный парк Коледино, д 6, стр. 1,
            </p>
            <p><strong>От:</strong> ФИО</p>
            <p><strong>адрес:</strong> г. Москва, Сколковское шоссе,</p>
            <h1>ПРЕТЕНЗИЯ</h1>
            <p>
              «16 » апреля 2023года я через интернет-магазин компании ООО «Вайлдберриз» на сайте www.wildberries.ru заключил договор купли-продажи товаров «юбка женская джинсовая» с артикулом 122039307 и 122027325 в количестве 2 шт. на сумму 7 760 (семь тысяч семьсот шестьдесят) руб.
            </p>
            <p>
              Полная оплата товара была произведена мною в день покупки.
            </p>
            <p>
              Факт приобретения и оплаты указанного выше товара подтверждается чеком ККМ №______ от __________ на сумму 7 760 (семь тысяч семьсот шестьдесят) руб. 00 коп. При получении товара информация о порядке и сроках возврата товара надлежащего качества мне не была предоставлена в письменной форме. Что противоречит п.2 ст. 26.1 Закона РФ от 07.02.1992 N 2300-1 «О защите прав потребителей».
            </p>
            <p>
              На следующий день, то есть «16» апреля 2023 года, я обратилась к сотрудникам указанного выше интернет-магазина с целью возврата товара и получения за него денежных средств, так как приобретенный товар не подошел мне по техническим характеристикам. При этом товар в употреблении не был, сохранены товарный вид и упаковка, комплектность, а также товарно-транспортные документы на его доставку и техническая документация.
            </p>
            <p>
              Однако, возврата денежных средств так и не произошло. Мной был написан ряд обращений к технической службе продавца по истечении 10 календарных дней, но в период с 17 апреля до 18 мая ответом был статус «На рассмотрении».
            </p>
            <p>
              Согласно ч. 4 ст. 26.1 Закона РФ от 07.02.1992 N 2300-1 «О защите прав потребителей»:
            </p>
            <p>
              «Потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней.
            </p>
            <p>
              В случае если информация о порядке и сроках возврата товара надлежащего качества не была предоставлена в письменной форме в момент доставки товара, потребитель вправе отказаться от товара в течение трех месяцев с момента передачи товара.
            </p>
            <p>
              Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара.
            </p>
            <p>
              При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования».
            </p>
            <p>
              По внутреннему регламенту товарного агрегатора ООО «Вайлдберриз» возврат денежных средств происходит на призванную, в личном кабинете пользователя, карту в течении 10 календарных дней.
            </p>
            <p>
              На основании изложенного:
            </p>
            <h2>ПРОШУ:</h2>
            <ul>
              <li>
                Осуществить возврат денежных средств в сумме 7 760 (семь тысяч семьсот шестьдесят) рублей, переданных ООО «Вайлдберриз» за приобретённые «17» апреля 2023 года товары «юбка женская джинсовая» с артикулами 122039307 и 122027325 в количестве 2 шт.
              </li>
              <li>
                Возврат осуществить на привязанную банковскую карту, с которой производилась оплата за товары.
              </li>
              <li>
                Возместить расходы за оказание юридической помощи по составлению претензии в размере 5 000 (пяти тысяч) рублей.
              </li>
              <li>
                В случае неудовлетворения претензии, согласно ФЗ «О защите прав потребителей» оставляю за собой право на обращение в суд с иском о взыскании денежных средств и штрафа в размере 50% от стоимости товара, возмещения расходов на юридические услуги, судебных расходов и морального вреда.
              </li>
            </ul>
            <p>Ответ прошу направить на электронну почту</p>
            <p>« » мая 2023 года Подпись: ________________</p>
          </div>
        </div>
      </section>
      <FooterComponent />
    </div>
  </template>
  
  <script>
  import HeaderComponent from '@/components/HeaderComponent.vue';
  import FooterComponent from '@/components/FooterComponent.vue';
  
  export default {
    name: 'TemplatesPage',
    components: {
      HeaderComponent,
      FooterComponent
    },
    methods: {
      navigateToTemplate() {
        // Логика перехода при клике на элемент списка
        this.$router.push({ path: '/templates' });
      }
    }
  };
  </script>
  
  <style scoped>
  .content {
    display: flex;
    padding: 0 100px;
    padding-top: 200px;
    background: #f9f9f9;
  }
  
  /* Сайдбар */
  .sidebar {
    display: grid;
    flex-direction: column;
    min-width: 220px;
    margin-top: 20px;
    gap: 20px;
  }
  
  .category {
    margin-bottom: 20px;
    background-color: #ffffff;
  }
  
  .category .title {
    background: #D6DAD8;
    padding: 10px 0;
    text-align: center;
    color: #31473A;
  }
  
  .category ul {
    list-style-type: none;
    padding: 0;
  }
  
  .category li {
    cursor: pointer;
    color: #000;
    margin: 10px 20px;
    word-wrap: break-word;
  }
  
  .category li:hover {
    text-decoration: underline;
  }
  
  /* Контейнеры для контента */
  .articles {
    flex: 1;
    padding: 20px;
  }
  
  .template-section {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 30px 50px;
    max-width: 860px;
    word-wrap: break-word;
  }
  
  .template-section h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #31473A;
  }
  
  .template-section h2 {
    font-size: 20px;
    margin: 20px 0 10px;
    color: #31473A;
  }
  
  .template-section p {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 10px;
  }
  
  .template-section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .template-section li {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 5px;
    color: #000;
  }
  </style>
  